import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

const Header = () => {
    return (
        <header>
            <Navbar bg="primary" variant='dark' expand="lg" fixed="top" collapseOnSelect>
                <Container>
                    <Navbar.Brand href="/">Fundación Pozos de Agua Viva</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="my-3 navlinks">
                            <Nav.Link href="/#projects"><i className="fa-solid fa-face-smile"></i> Obras sociales</Nav.Link>
                            <Nav.Link href="/#team"><i className="fa-solid fa-people-group"></i> Nuestro equipo</Nav.Link>
                            <Nav.Link href="/#contact"><i className="fa-solid fa-phone"></i> Contacto</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;