import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import LoadingBox from '../components/LoadingBox';
import ToastMessage from '../components/ToastMessage';

const ContactScreen = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [token, setToken] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const captchaRef = useRef();

    const charactersLimit = 580;

    const submitHandler = async (e) => {
        e.preventDefault();

        // validate captcha
        if (!token) {
            setIsSuccess(false);
            setToastMessage('Debes verificar que eres humano');
            return;
        }

        setLoading(true);
        setDisabled(true);

        // send form
        const { data } = await axios.post('/api/contact', {
            name: name.trim().slice(0, 50),
            email: email.trim().slice(0, 50),
            message: message.trim().slice(0, charactersLimit),
            token
        });

        if (data.errorMessage) {
            setIsSuccess(false);
            setToastMessage(data.errorMessage);
        } else {
            setIsSuccess(true);
            setToastMessage(data.message);
        }

        // clear form
        setName('');
        setEmail('');
        setMessage('');
        e.target.reset();
        setLoading(false);
    };

    return (
        <div>
            <h1 className='my3'>Contáctenos</h1>
            <FormContainer>
                <Form onSubmit={submitHandler} method="post">
                    <Form.Group className="my-3" controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" placeholder="Ingresa tu numbre" onChange={(e) => setName(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="my-3" controlId="email">
                        <Form.Label>Correo Electrónico</Form.Label>
                        <Form.Control type="email" placeholder="Ingresa tu correo electrónico" onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="message">
                        <Form.Label>Mensaje</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Déjanos tu mensaje" onChange={(e) => setMessage(e.target.value)} />
                        <span className='charactersLeft'>{charactersLimit - message.length} caracteres restantes</span>
                    </Form.Group>
                    <HCaptcha
                        sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
                        onVerify={setToken}
                        ref={captchaRef}
                    />
                    <Button className="my-2" variant="primary" type="submit" disabled={disabled}>
                        Enviar
                    </Button>
                    {toastMessage && <ToastMessage message={toastMessage} isSuccess={isSuccess} />}
                </Form>
                {loading && <LoadingBox />}
            </FormContainer>
        </div>
    );
};

export default ContactScreen;