import React, { useState } from 'react';
import { Button, Col, Collapse, Container, Row } from 'react-bootstrap';

const HomeScreen = () => {
    const [misionOpen, setMisionOpen] = useState(false);
    const [visionOpen, setVisionOpen] = useState(false);

    return (
        <div>
            <Row>
                <Col>
                    <img className='imglogo my-5' src='https://res.cloudinary.com/dyoim2six/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_purple,b_rgb:262c35/v1675095917/PozosAguaViva/Screen_Shot_2023-01-30_at_11.25.03_AM_oiytuk.png' alt='pozosAguaVivaLogo' />
                    <div className='corintios-text'>
                        <h1 className='corintios-title'>1 Corintios 6:14</h1>
                        <em className='corintios-em'>“Y Dios, que levantó al Señor, también a nosotros nos levantará con su poder.”</em>
                    </div>
                </Col>
            </Row>
            <hr className='divider-dot'></hr>
            <Row>
                <Col sm={12} md={12}>
                    <h2 className='aboutus-title'>¿Quienes somos?</h2>
                    <p>Institución sin ánimo de lucro, fundada en la ciudad de Medellín el 12 de Octubre de 2014 y en su compromiso institucional se está preparando para los servicios en las áreas espiritual, psicológica, pedagógica y sociológica; brindando tratamientos terapéuticos a los niños(as), adolecentes, jóvenes, hombres y mujeres de la calle, ancianos desvalidos y a sus familias para su sanidad Espiritual, mental y física; así mismo, generar a través de un programa de formación integral del individuo, su rehabilitación, su formación familiar y social, pacífica y productiva.</p>
                </Col>
                <Col className='my-3' sm={12} md={6}>
                    <div className='mision-title'>
                        <Button className='collapse-btn'
                            onClick={() => setMisionOpen(!misionOpen)}
                            aria-controls="mision-collapse-text"
                            aria-expanded={misionOpen}
                        >{misionOpen ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}</Button>
                        <h2>Misión</h2>
                    </div>
                    <Collapse in={misionOpen}>
                        <p id='mision-collapse-text'>Apoyar el desarrollo integral del ser humano y trabajar en la reinserción social de las personas con problemas de adicción y/o malos comportamientos, para reducir el flagelo de las adicciones y las problemáticas asociadas a ellas. Establecer vínculos con las personas y las familias que están impactadas por la problemática de las drogas, al igual que todas aquellas que afectan la relación con Dios, la familia, el círculo social, entre otros.</p>
                    </Collapse>
                </Col>
                <Col className='my-3' sm={12} md={6}>
                    <div className='vision-title'>
                        <Button className='collapse-btn'
                            onClick={() => setVisionOpen(!visionOpen)}
                            aria-controls="vision-collapse-text"
                            aria-expanded={visionOpen}
                        >{visionOpen ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}</Button>
                        <h2>Visión</h2>
                    </div>
                    <Collapse in={visionOpen}>
                        <p id='vision-collapse-text'>Ser parte de la obra de Dios en la restauración de las personas y familias; en donde la palabra de Dios sea el fundamento y la medicina para recibir abundancia de PAZ Y VERDAD.</p>
                    </Collapse>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col className='ytvideo-col' sm={12} md={12}>
                    <iframe className='ytvideo' width="560" height="315" src="https://www.youtube.com/embed/ZU6ACH39LW0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Col>
            </Row>
        </div>
    );
};

export default HomeScreen;