import React from 'react';

const NotFoundScreen = () => {
    return (
        <div className='notfound-page'>
            <h1>404</h1>
            <h1>Página no encontrada</h1>
            <h1 style={{ fontSize: "100px" }}>&#128123;</h1>
        </div>
    );
};

export default NotFoundScreen;