import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import axios from 'axios';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

const SingleProjectScreen = () => {
  const [project, setProject] = useState({});
  const [photos, setPhotos] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fectchProject = async () => {
      try {
        const { data } = await axios.get(`/api/projects/:${id}`);
        setProject(data);
        setPhotos(data.photosUrl);
      } catch (error) {
        console.log(error);
      }
    };
    fectchProject();
  }, [id]);

  return (
    <div className='my-5'>
      <Container>
        <h1 className='container-gallery'>{project.title}</h1>
        <h2 style={{ color: "#242424" }}>{project.description}</h2>
        <Row className='my-3'>
          {photos.map((photo) => (
            <Col className='my-2' key={photo} sm={12} md={6} lg={4} xl={3}>
              <Card style={{ border: "none" }}>
                <Link to={photo} target='_blank'>
                  <Card.Img className='photo-gallery' src={photo} variant='top' />
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
        <Button className='btn btn-dark' onClick={goBack}>Regresar</Button>
      </Container>
    </div>
  );
};

export default SingleProjectScreen;