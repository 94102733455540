import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Project = ({ id, title, description, photoProject, photosUrl }) => {
    const profileImage = photoProject;

    return (
        <div>
            <Container className='project-container my-3'>
                <Row>
                    <Col sm={12} md={6}>
                        <Link to={`/projects/${id}`}>
                            <img className='project-img my-3' src={profileImage} />
                        </Link>
                    </Col>
                    <Col sm={12} md={6}>
                        <Link to={`/projects/${id}`}>
                            <h4 className='project-title my-2'>{title}</h4>
                        </Link>
                        <p className='py-3'>{description}</p>
                        <Link className='project-link' to={`/projects/${id}`}>Ver galeria</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Project;