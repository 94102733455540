import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import TeamMember from '../components/TeamMember';

const TeamScreen = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [active, setActive] = useState('');

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const { data } = await axios.get('/api/teammembers');
                setTeamMembers(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchTeamMembers();
    });

    return (
        <div>
            <h1>Nuestro Equipo</h1>
            <div id="testimonial-carousel" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {teamMembers.map((m) => (
                        m.id == 1 ? (
                            <div key={m.id} className={`carousel-item active container-fluid`}>
                            <img className="testimonial-image" src={m.profilePicture} alt="dog-profile" />
                            <h2 className="testimonial-text">{m.name}</h2>
                            <em className='em-member-position'>{m.position}</em>
                        </div>
                        ) : (
                            <div key={m.id} className={`carousel-item container-fluid`}>
                            <img className="testimonial-image" src={m.profilePicture} alt="dog-profile" />
                            <h2 className="testimonial-text">{m.name}</h2>
                            <em className='em-member-position'>{m.position}</em>
                        </div>
                        )
                        
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#testimonial-carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#testimonial-carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>
        </div>
    );
};

export default TeamScreen;