import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import ProjectScreen from './screens/ProjectScreen';
import TeamScreen from './screens/TeamScreen';
import DocumentsScreen from './screens/DocumentsScreen';
import ContactScreen from './screens/ContactScreen';

function App() {

  return (
    <div>
      <main className='py-5'>
        <Container className='my-3'>
          <HomeScreen />
          <hr className='divider-dot'></hr>
          <section id='projects'>
            <ProjectScreen />
          </section>
          <hr className='divider-dot'></hr>
          <section id='team'>
            <TeamScreen />
          </section>
          <hr className='divider-dot'></hr>
          <section id='contact'>
            <ContactScreen />
          </section>
        </Container>
      </main>
    </div>
  );
}

export default App;
