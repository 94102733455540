import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <Container className='footer-container'>
                <div className='d-flex justify-content-evenly footer-social-icons'>
                    <div className='footer-contact-info'>
                        <p className='footer-contact-title'><i className="fa-solid fa-location-dot"></i> Dirección:</p>
                        <p className='footer-contact-text'>Medellín, Colombia</p>
                        <p className='footer-contact-text'>Calle 43 No 78 - 15</p>
                    </div>
                    <div className='footer-contact-info'>
                        <p className='footer-contact-title'><i className="fa-solid fa-phone"></i> Teléfono:</p>
                        <p className='footer-contact-text'>+57 316 690-1786</p>
                        <p className='footer-contact-text'><strong>Nit: </strong>900790155-6</p><br />
                        <Link className='legaldocs-link' to={process.env.REACT_APP_DOCS_DRIVE_LINK} target='_blank'><i className="fa-solid fa-folder"></i> DOCUMENTOS LEGALES</Link>
                    </div>
                </div>
                <div className='singleCol footer-icons-box d-flex justify-content-evenly'>
                    <a href='https://www.facebook.com' target='_blank'><i className="fa-brands fa-facebook"></i></a>
                    <a href='https://www.instagram.com' target='_blank'><i className="fa-brands fa-instagram"></i></a>
                    <a href='https://www.youtube.com/channel/UCApn11ZqlMKjI5sexYcUPzQ' target='_blank'><i className="fa-brands fa-youtube"></i></a>
                </div>
                <Row>
                    <Col style={{ fontWeight: "bold" }} className='text-center py-3'>&copy; Fundación Pozos de Agua Viva</Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;