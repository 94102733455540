import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Project from '../components/Project';
import axios from 'axios';

const ProjectScreen = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const { data } = await axios.get('/api/projects');
                setProjects(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchProjects();
    }, []);

    return (
        <div className='my-3'>
            <h1>Obras Sociales</h1>
            {projects.map((project) => <Project
                key={project.id}
                id={project.id}
                title={project.title}
                description={project.description}
                photoProject={project.photoProject}
                photosUrl={project.photosUrl}
                video={project.video}
            />)}
        </div>
    );
};

export default ProjectScreen;