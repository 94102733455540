import React, { useState } from 'react';
import moment from 'moment';

const ToastMessage = ({ message, isSuccess }) => {
    // const [today, setToday] = useState('');

    const errorStyle = {
        backgroundColor: "#FD5D5D"
    };
    const successStyle = {
        backgroundColor: "#38E54D"
    };
    //"&#128513;" : "&#128532;"
    return (
        <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div style={isSuccess ? successStyle : errorStyle} className="toast-header">
                <span className="me-auto">{isSuccess ? <span className='toast-emoji'>&#128513;</span> : <span className='toast-emoji'>&#128532;</span>}</span>
                <strong>{moment().format("ddd, hA")}</strong>
                <button type="button" className="btn-close ms-2 mb-1" data-bs-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div className="toast-body">
                {message}
            </div>
        </div>
    );
};

export default ToastMessage;